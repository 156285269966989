.form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  column-gap: 0.875rem;

  > div {
    flex: 1;
  }
}
