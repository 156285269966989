.table-container {
  width: 100%;
  // max-height: 70vh;
  overflow: auto;
  border-radius: 3px;
  // background-color: $opacity-level;
  // border: 1px solid rgba($white, 0.2);

  .custom {
    table {
      border-collapse: collapse;
      width: 100%;
    }
    th,
    td {
      padding: 1rem 16px;
      white-space: nowrap;
      font-weight: normal !important;
      // font-size: 1rem;
    }

    thead th {
      position: sticky;
      top: 0;
      z-index: 1;
      color: #cecece;
      text-align: left;
    }

    tbody {
      color: #cecece;
    }
  }
}

.custom-row {
  cursor: pointer;
  td {
    border-top: 1px solid $opacity-level;
    border-bottom: 1px solid $opacity-level;
    font-size: smaller;
  }

  &:hover {
    background-color: $opacity-level;
  }
}

.custom-row-cell-semaforo-danger {
  background-color: rgba(255, 7, 7, 0.15);
}

.custom-row-cell-semaforo-warning {
  background-color: rgba(255, 193, 7, 0.15);
}

.custom-row-subsuma td {
  padding: 5px 0px 5px 5px;
  border-bottom: 1px solid rgba(255, 193, 7, 0.5);
  // border-top: 1px solid rgba(255, 193, 7, 1);
}

.custom-row-subsuma-final td {
  padding: 20px 0px 20px 5px;
  // border-top: 1px solid rgba(255, 152, 0, 1);
  border-bottom: 1px solid rgba(255, 152, 0, 0.5);
}

.custom-row-deleted {
  background-color: rgba(255, 7, 7, 0.15);
}

.custom-row-nuevo {
  background-color: rgba(139, 195, 74, 0.15);
}

.custom-row-anticipated {
  background-color: rgba(255, 152, 0, 0.15);
}

.custom-row-cell {
  padding: 5px 8px;
  white-space: nowrap;

  &.hide-contents > * {
    color: red;
  }
}

.custom-row-cell-centered-text {
  text-align: center;
}

.custom-row-cell-two-values {
  display: flex;
  flex-direction: column;
  align-items: center;

  > span:nth-child(1) {
    font-size: 0.8rem;
  }
}

.custom-row-cell-two-values-text {
  /* font-weight: bold; */
  color: $default-font-color;
  // font-size: 1.2rem;

  svg {
    margin-bottom: -6px;
  }
}

.custom-row-cell-two-values-text-positive {
  color: #4caf50;
  vertical-align: middle;
}

.custom-row-cell-two-values-text-negative {
  color: #f44336;
  vertical-align: middle;
}

.custom-row-cell-two-values-separator {
  height: 20px;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.2);
}

.row-cell-informational {
  display: flex;
  flex-direction: column;
  span {
    margin: 3px 0;
    color: $default-font-color;
  }

  > span:nth-child(1) {
    color: rgb(255, 193, 7);
  }
}
