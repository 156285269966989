.equipos-list {
  box-sizing: border-box;
  gap: 15px;
}

.equipos-list-container {
  padding: 2rem;
  background-color: $accent-background;
  border-radius: 8px;

  @media screen and (max-width: $breakpoint-mini-tablet) {
    padding: 1rem 2rem 1rem 1rem;
  }

  a {
    display: flex;
    text-decoration: none;
    color: $default-font-color;
    height: 100%;
  }
}

.equipo-card {
  position: relative;
  padding: 1rem 1rem;
  height: 110px;
  background: $gradient-haasten-vertical;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  transition: all 0.3s;
  &:hover {
    transform: scale(1.1);
    transition: all 0.3s;
  }

  .equipo-card-title-icon {
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 40px;
    height: 40px;
    padding: auto;
    border-radius: 8px;
    background: rgba($haasten-color, 0.9);
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.5);
    display: table;
    text-align: center;
    h3 {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .equipo-card-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;
  }

  .equipo-card-three-dots-menu {
    position: absolute;
    top: 8px;
    right: -12px;
    background-color: $haasten-color-light;
    border-radius: 50%;
  }
}

.equipo-card-selected {
  background: $gradient-haasten;
  padding: 1rem 2rem 1rem;
  margin-left: 20px;

  .equipo-card-title-icon {
    left: 0%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
