.ubicacion-toolbar {
  display: flex;
  flex-wrap: wrap;
  flex: 3;
  column-gap: 10px;

  .MuiFormControlLabel-root {
    margin-right: 0px;
  }

  > * {
    flex: 1;
  }

  .MuiButtonBase-root {
    color: $accent-color-light !important;
  }

  .MuiFormControl-root > * {
    color: $default-font-color !important;

    .MuiSelect-icon {
      color: $default-font-color !important;
    }
  }
}

.MuiPaper-root {
  .MuiListItemText-root {
    span {
      color: $default-font-color !important;
    }
  }
}

.marker-load {
  .map-marker-icon {
    background: $gradient-warning !important;
  }
  .map-marker-cone {
    border-top-color: $yellow !important;
  }
}

.marker-download {
  .map-marker-icon {
    background: $gradient-info !important;
  }
  .map-marker-cone {
    border-top-color: $blue !important;
  }
}
