.form-card {
  width: 100%;
  padding: 1rem;
  background-color: rgba($white, 0.07);
  border: 1px solid rgba($white, 0.2);
  border-radius: 5px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  gap: 8px 1rem;
  grid-template-areas:
    'icon title'
    'icon content'
    'icon content';

  .form-card-title-icon {
    grid-area: icon;
    width: 40px;
    height: 40px;
    padding: auto;
    border-radius: 3px;
    background: $gradient-haasten;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.1);
    display: table;
    text-align: center;
    h3 {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .form-card-title {
    grid-area: title;
    display: grid;
    grid-template-columns: 1fr auto;

    h2 {
      margin: 0;
      font-weight: normal;
      height: 40px;
      line-height: 40px;
    }
  }

  .form-card-content {
    grid-area: content;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;

    small,
    span {
      margin-bottom: 3px;
    }
  }
}
