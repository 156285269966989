.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  z-index: 1;

  .page-selector-toolbar {
    display: contents;

    .react-datepicker-popper {
      z-index: 4;
    }

    .equipos-list-container {
      z-index: 5;
    }
  }

  .content-view {
    padding-bottom: 0 !important;
    position: relative;
    flex-grow: 1;
    overflow: hidden;
  }

  .mapa {
    height: 30%;
    width: 100%;
    z-index: 2;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.5s ease;

    &:not(.open) {
      height: 90%;
      transition: all 0.5s ease;
    }
  }

  .slide {
    position: absolute;
    width: 100%;
    height: 70%;
    top: 60%;
    left: 0;
    transition: all 0.5s ease;
    display: flex;
    flex-direction: column;
    z-index: 3;
    background-color: $default-background;
    border-top: 5px solid $accent-color-light;

    &.open {
      top: 30%;
      transition: all 0.5s ease;
    }

    .toggle-button {
      @extend %button-icon;
      position: absolute;
      left: 1rem;
      bottom: 101%;
      background: $default-background-content-extra-lighter;
      border-radius: 5px 5px 0 0;
      font-size: 1rem;
      align-items: center;
      column-gap: 8px;

      span {
        white-space: nowrap;
        font-size: 1rem;
      }

      &:hover {
        background: $default-background-content;
      }

      svg {
        transform: rotate(-90deg);
        transition: all 0.5s ease;
      }
      &.open svg {
        transform: rotate(90deg);
        transition: all 0.5s ease;
      }
    }
  }
}
