@import 'base/_variables.scss';
@import 'base/_placeholders.scss';
@import 'base/_mixins.scss';
@import '~react-datepicker/dist/react-datepicker.css';
@import url('https://fonts.googleapis.com/css2?family=Archivo:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

// Global styles
body,
#root {
  margin: 0;
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $default-font-color;
  background: $default-background;
  cursor: default;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

code {
  font-family: 'Archivo', sans-serif;
}

img {
  @extend %img-full-cover;
}

@import 'modules/_home.scss';
@import 'modules/_login.scss';
@import 'modules/_app-navigation.scss';
@import 'modules/layout/_index.scss';
@import 'modules/components/_index.scss';
