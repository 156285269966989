.app-navigation-sidebar {
  grid-area: sidebar;
  position: relative;
  top: 0;
  left: 0;
  display: grid;
  grid-template-rows: $header-height $header-height auto 1fr;
  grid-template-areas: 'logo' 'userbadge' 'routes' $header-height;
  height: 100%;

  @media screen and (max-width: $breakpoint-tablet) {
    height: 100vh;
    width: 100vw;
    position: fixed;
    overflow-y: auto;
    z-index: 200000; /* Needs to sit above the hamburger menu icon */
    transform: translateX(-100%);
    transition: all 0.6s ease-in-out;
    background: $default-background;

    &.active {
      transform: translateX(0);
    }
  }
}

.app-mobile-header {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  background-color: $default-background;
  color: $white;
  border-bottom: 1px solid $default-border-color;
  user-select: none;

  @media screen and (min-width: $breakpoint-tablet) {
    display: none;
  }

  > div:nth-child(1) {
    display: initial;
    cursor: pointer;
  }
}
