$marker-size: 40px;
$marker-cone-size: $marker-size / 3;
$marker-size-dot: 8px;

.map-marker {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: $marker-size;
  margin-top: calc(-#{$marker-size} - #{$marker-cone-size});
  position: relative;

  span {
    position: absolute;
    top: -35px;
    white-space: nowrap;
    background-color: $default-background-content-extra-lighter;
    padding: 5px 8px;
    border-radius: 8px;
  }

  .map-marker-icon {
    width: $marker-size;
    height: $marker-size;
    background: $gradient-haasten;
    border-radius: 50%;
    z-index: 2;

    .map-marker-img {
      width: calc(#{$marker-size} - 10px);
      height: calc(#{$marker-size} - 10px);
      margin-top: 5px;
      margin-left: 5px;
      background: white;
      border-radius: 50%;

      img {
        height: 100%;
        width: auto;
      }
    }
  }

  .map-marker-cone {
    width: 0;
    height: 0;
    border-left: #{$marker-cone-size} solid transparent;
    border-right: #{$marker-cone-size} solid transparent;
    border-top: #{$marker-cone-size} solid $accent-color;
    margin-top: -5px;
    margin-left: 1px;
    z-index: 1;
  }
}

.map-marker-dot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: $marker-size-dot;
  margin-top: -$marker-size-dot;
  position: relative;

  &:hover {
    span {
      opacity: 1 !important;
    }
  }

  span {
    position: absolute;
    top: -25px;
    white-space: nowrap;
    background-color: $default-background-content-extra-lighter;
    padding: 5px 8px;
    border-radius: 8px;
    opacity: 0;
  }

  .map-marker-dot-icon {
    width: $marker-size-dot;
    height: $marker-size-dot;
    background: $gradient-haasten;
    border-radius: 50%;
    z-index: 2;
  }
}
