.fader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.to-left {
    background: linear-gradient(to right, transparent, rgba(0, 0, 0, 1));
  }

  &.to-top {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }

  &.to-right {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }

  &.to-bottom {
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.9) 0%,
      rgba(0, 0, 0, 0) 20%,
      rgba(0, 0, 0, 0) 80%,
      rgba(0, 0, 0, 0.9) 100%
    );
  }
}
