$gap: 1rem;
$gap-small: 0.5rem;

.equipos-favoritos-container {
  display: flex;
  flex-wrap: wrap;
  gap: $gap;
  width: 100%;
  margin-bottom: 1rem;

  @media screen and (max-width: $breakpoint-tablet) {
    justify-content: space-between;
    gap: $gap-small;
  }

  .equipo-item {
    flex: 0 0 250px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 1rem 0.5rem;
    background-color: rgba($white, 0.07);
    border-radius: 5px;
    &:hover {
      background-color: rgba($white, 0.14);
    }

    > :nth-child(1) {
      flex-grow: 1;
    }

    @media screen and (max-width: $breakpoint-tablet) {
      flex-basis: calc(50% - #{$gap-small / 2});
    }
  }

  .equipo-item-title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 1rem;

    > div:nth-child(1) {
      flex-grow: 1;
    }

    h3 {
      margin: 0;
      font-weight: normal;
    }
  }

  .equipo-nivel {
    font-size: large;
    font-weight: bold;
  }

  .equipo-bomba-small {
    flex: 0 0 30px;
    height: 30px;
    padding: 5px;
  }
}

.icon-fav {
  fill: $accent-color;
  font-size: large;
  cursor: pointer;
}
