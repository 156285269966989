.cmas-ingredientes-columns {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;

  .form-card {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    grid-template-columns: 1fr;
  }
}
