.login-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas: 'form img';

  .login-logo {
    position: absolute;
    top: 5vh;
    left: 5vw;
    width: 64px;
    height: auto;
  }

  @media screen and (max-width: $breakpoint-tablet) {
    grid-template-areas: 'form';
    grid-template-columns: 1fr;
  }

  .form {
    grid-area: form;
    color: $default-font-color;
    background-color: $default-background;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 2;

    form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: stretch;
      width: 80%;
    }

    @media screen and (max-width: $breakpoint-tablet) {
      background-color: rgba($default-background, 0.5);
    }
  }

  .background {
    grid-area: img;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 1;

    @media screen and (max-width: $breakpoint-tablet) {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
}
