.user-badge-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $haasten-color-light;
  font-size: small;
  gap: 8px;
  border: 1px solid $haasten-color-light;
  border-radius: 15px;
  padding: 0.5rem 1rem;

  svg {
    color: $accent-color;
  }
}
