.view-header {
  padding: 2rem;
  background-color: $accent-background;
  border-radius: 8px;
  box-sizing: border-box;
  gap: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: $breakpoint-mini-tablet) {
    padding: 1rem;
  }
}
