.toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  > h2:nth-child(1) {
    flex-grow: 1;
    margin-left: 0;
  }

  > div:nth-child(2) {
    width: auto;
  }
}

.header-toolbar {
  padding-right: 0.5rem;
}
