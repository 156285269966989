.alert-container {
  padding: 1rem;
  border-radius: $border-radius;
  margin: 1.5rem 0;

  &.fail {
    background-color: rgba($red, 0.2);
    color: $red;
  }

  &.info {
    background: $gradient-haasten;
  }

  &.warning {
    background: $gradient-danger;
    color: $black;
  }

  &.no-margin {
    margin: 0;
  }
}
