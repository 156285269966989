.slider {
  .MuiSlider-rail {
    color: $accent-color-light !important;
  }
  .MuiSlider-track,
  .MuiSlider-thumb {
    color: $accent-color-light !important;
  }
  .MuiSlider-markLabel {
    color: $default-font-color !important;
  }
}
