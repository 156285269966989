.icon-s {
  width: 1rem;
  height: 1rem;
}

.icon-m {
  width: 1.5rem;
  height: 1.5rem;
}

.icon-l {
  width: 2rem;
  height: 2rem;
}
