.button-icon {
  @extend %button-icon;
  @include button();
}

.button-submit {
  @extend %button-submit;
  @include button();
}

.small-button {
  @include button();
  line-height: $input-height * 0.75;
  height: $input-height * 0.75;
  font-size: $input-font-size-small;
}
