.input-wrapper {
  .end-adornment {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .end-adornment-clickable {
    cursor: pointer;
  }
}

// Fix para que el autocomplete de Chrome no sobreescriba los estilos
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-transition-delay: 99999s;
}
