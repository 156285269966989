.app-container {
  height: 100vh;
  width: 100vw;
  display: grid;
  overflow-y: auto;
  grid-template-columns: $dashboard-sidebar-width 1fr;
  gap: 0px 0px;
  grid-template-areas: 'sidebar content';

  @media screen and (max-width: $breakpoint-tablet) {
    grid-template-areas: 'header' 'content';
    grid-template-rows: $header-height auto;
    grid-template-columns: 1fr;
  }
}

.app-content {
  grid-area: content;
  width: calc(100vw - #{$dashboard-sidebar-width});
  color: $default-font-color;

  @media screen and (max-width: $breakpoint-tablet) {
    width: 100vw;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: normal !important;
    margin: 0;
  }

  .content-view {
    padding-bottom: 25px !important;
  }
}

.appear-on-mobile {
  display: none;

  @media screen and (max-width: $breakpoint-mini-tablet) {
    display: contents;
  }
}

.hide-on-mobile {
  @media screen and (max-width: $breakpoint-mini-tablet) {
    display: none;
  }
}
