.search-bar {
  margin-bottom: 1rem;

  .search-box {
    width: 100%;
    height: 48px;
    border: 1px solid $accent-color;
    border-radius: 20px;
    background-color: rgba($dark-grey, 0.5);
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
    position: relative;

    .search-box-input {
      flex-grow: 1;
      border: none;
      background-color: transparent;
      height: 100%;
      padding: 0 1rem;
      color: $white;
      font-size: 1rem;
    }

    .search-box-clear-button {
      @extend %button-icon;
      position: absolute;
      right: 1rem;
    }
  }

  .filter-bar {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    padding: 0 1rem;

    .filter-button {
      @extend %button;
    }
  }
}
