.data-list-container {
  display: flex;
  flex-direction: column;
}

.data-list-item {
  flex-grow: 1;
  display: flex;
  padding: 1rem;
  cursor: pointer;
  &:hover {
    background-color: rgba($white, 0.07);
  }

  + .data-list-item {
    border-top: 1px solid $grey;
  }

  .data-list-item-img-container {
    flex: 0 0 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 1rem;
  }

  @media only screen and (max-width: 400px) {
    .data-list-item-img-container {
      flex: 0 0 48px;
      height: 48px;
    }
  }

  .data-list-item-content {
    color: $white;
    flex-grow: 1;

    .data-list-item-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 1rem;

      h4 {
        margin: 0;
        margin-right: 0.5rem;
      }

      small {
        color: $grey;
      }
    }

    .data-list-item-tags {
      display: flex;
      flex-wrap: wrap;
      margin: 0;

      > small {
        color: $white;
        border: 1px solid $accent-color;
        border-radius: $border-radius;
        background-color: rgba($accent-color, 0.5);
        padding: 2px 5px;
        margin: 2px;
      }
    }
  }
}
