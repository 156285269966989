.lite-table-container {
  width: 100%;
  overflow: auto;
  border-radius: 3px;

  table {
    border-collapse: collapse;
    width: 100%;
  }
  th,
  td {
    white-space: nowrap;
    font-weight: normal !important;
  }

  td {
    padding: 8px;
    font-size: 1rem;
    text-align: left;
    border-bottom: 1px solid $default-border-color;

    &.centered {
      text-align: center;
    }
  }

  th {
    position: sticky;
    top: 0;
    z-index: 1;
    color: #cecece;
    text-align: left;
    background-color: $opacity-level;
    padding: 16px 8px;
    font-size: 0.9rem;
  }

  tbody {
    color: #cecece;
  }
}
