.space-divider {
  width: 100%;
  height: 1rem;

  &.double {
    height: 2rem;
  }
}

.separator {
  height: 1px;
  width: 100%;
  background-color: $default-border-color;
}
