.marker-mezclado-inicio {
  span {
    border: 2px solid green;
  }
}
.marker-mezclado-fin {
  span {
    border: 1px solid red;
  }
}
